import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b9fc8678"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_2 = { class: "col-lg-3 ms-4" }
const _hoisted_3 = { class: "col ms-4" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-header card-header-stretch" }
const _hoisted_6 = { class: "card-title d-flex align-items-center" }
const _hoisted_7 = { class: "svg-icon svg-icon-1 svg-icon-primary me-3 lh-0" }
const _hoisted_8 = { class: "fw-bolder m-0 text-gray-800" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = {
  id: "kt_activity_today",
  class: "card-body p-0 tab-pane fade show active",
  role: "tabpanel",
  "aria-labelledby": "kt_activity_today_tab"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Teacher_card = _resolveComponent("Teacher-card")!
  const _component_RessourceCard = _resolveComponent("RessourceCard")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_DetailsSection = _resolveComponent("DetailsSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Teacher_card, {
        name: _ctx.teacher.name,
        position: _ctx.teacher.position,
        online: _ctx.teacher.online,
        avatar: _ctx.teacher.avatar
      }, null, 8, ["name", "position", "online", "avatar"]),
      (_ctx.course.resources && _ctx.course.resources.length != 0)
        ? (_openBlock(), _createBlock(_component_RessourceCard, {
            key: 0,
            resources: _ctx.course.resources
          }, null, 8, ["resources"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com010.svg" })
            ]),
            _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.course.title), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_DetailsSection, {
              title: _ctx.course.title,
              subject: _ctx.course.subject,
              module: _ctx.course.module,
              date: _ctx.course.date,
              description: _ctx.course.description,
              photos: _ctx.course.photos,
              type: "course"
            }, null, 8, ["title", "subject", "module", "date", "description", "photos"])
          ])
        ])
      ])
    ])
  ]))
}